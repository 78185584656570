<template>
  <b-card>
      <!-- Form -->
      <b-form
        class="p-2"
      >
        <b-row>
          <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Nama</label>
           <b-form-input
              id="nama"
              autofocus
              trim
              placeholder="Masukkan Nama Nara Hubung"
              v-model="rowData.data.nama"
            />
            <small class="mt-1" style="color: red;" v-if="error.nama">{{error.message}}</small>
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Email</label>
            <b-form-input
              id="email"
              trim
              placeholder=""
              type="email"
              v-model="rowData.data.email"
            />
        </b-col>
         <b-col
          cols="12"
          md="12"
          class="mb-md-0 mt-2"
        >
          <label>Telepon</label>
          <b-form-input
              id="telepon"
              trim
              placeholder=""
              v-model="rowData.data.telepon"
            />
        </b-col>
      </b-row>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="updateData"
          >
            Simpan
          </b-button>
        </div>

      </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,
  BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg,
  BFormSpinbutton, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { required, alphaNum, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { avatarText } from '@core/utils/filter'
import useList from '@/connection-api/narahubung/list'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import {
  uploadFileLogo,
} from '@/connection-api/narahubung'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
      },
      required,
      alphaNum,
      email,
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          altFormat: 'M j, Y',
          altInput: true,
          dateFormat: 'Y-m-d',
        },
      },
    }
  },
  props: {
    rowData: {
      type: Object,
      required: true,
    },
    negaraOptions: {},
    provinsiOptions: {},
    kotaOptions: {},
  },
  setup() {
    const { resolveUserRoleVariant, resolveUserStatusVariant } = useList()
    const blankUserData = {
    }
    // alert(this.userData.nama)
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const onSubmit = () => {
      store.dispatch('narahubung/updateData', this.rowData.data)
        .then(response => {
        })
        .catch(error => {

        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      avatarText,
      resolveUserRoleVariant,
      resolveUserStatusVariant,

      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
   
    async updateData() {
      store.dispatch('narahubung/updateData', this.rowData.data)
        .then(response => {
          this.makeToast('success', 'Simpan Data Jenis Dokumen Berhasil Dilakukan')
        })
        .catch(error => {
          // console.log(error)
        })
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatDate(value, format) {
      return moment(String(value)).format(format)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        // title: `Variant ${variant || 'default'}`,
        title: 'Biodata',
        variant,
        solid: true,
        autoHideDelay: 10000,
      })
    },
  },
}
</script>

<style>

</style>
